import { StarIcon } from "@heroicons/react/solid";
import * as Sentry from "@sentry/nextjs";
import { kv } from "@vercel/kv";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "motion/react";
import type { GetStaticPaths } from "next";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCurrentUser } from "queries/current-user";
import { Team, listTeams } from "queries/teams";
import { Ref, useEffect, useRef, useState } from "react";
import useInView from "react-cool-inview";
import toast from "react-hot-toast";
import slugify from "slugify";
import { Spinner } from "ui/components/icons/Spinner";
import { Badge } from "ui/components/primitives/badge";
import { Switch } from "ui/components/primitives/switch";
import { useSession } from "ui/containers/session";
import { captureEvent } from "ui/lib/captureEvent";
import { getTeamFromSlug } from "ui/lib/get-team-from-slug";
import { isDev } from "ui/lib/helpers";
import { useIsMounted } from "ui/lib/hooks";
import { cn } from "ui/lib/utils";
import { ErrorScreen } from "web/components/error-screen";
import { Footer } from "web/components/footer";
import { HeadContent } from "web/components/head";
import OptimizeLayout from "web/components/layout";
import { DesktopQRModal } from "web/components/modals/desktop-qr-modal";
import { TeamCTAButtonShell } from "web/components/team-cta-button";
import { WaitlistEmailCapture } from "web/components/team-waitlist-email-capture";
import { teamData } from "web/data/teams/data";
import { ExperimentProvider } from "web/state-containers/experiment";
const TeamCTAButton = dynamic(() => import("web/components/team-cta-button").then(mod => ({
  default: mod.TeamCTAButton
})), {
  ssr: false,
  loading: () => <TeamCTAButtonShell />
});
const TeamNavCTAButton = dynamic(() => import("web/components/team-cta-button").then(mod => ({
  default: mod.TeamNavCTAButton
})), {
  ssr: false
});
export const Header = ({
  team,
  slug = "",
  showCTA
}) => {
  const hardCodedTeamData = teamData[slug] || teamData["forged"];
  const {
    isReady
  } = useRouter();
  const {
    observe,
    inView = true
  } = useInView({
    threshold: 0 // Default is 0
  });
  return <>
      <div ref={observe as unknown as Ref<any>} className="absolute inset-y-0 top-0 w-full h-[36px] bg-transparent" />
      <div className={cn("sticky top-0 z-50 px-4 py-3 transition-colors duration-500", {
      "bg-background": !inView && isReady,
      "bg-gradient-to-b from-background to-transparent": inView
    })}>
        <div className="container mx-auto flex justify-between items-center">
          <div className="">
            <Link href="/" data-sentry-element="Link" data-sentry-source-file="[slug].tsx">
              <Image className="self-start w-[112px] h-[16px] lg:h-[17px] lg:w-[126px]" src={"/assets/logo/white.svg"} alt="Ladder Logo" width={126} height={126} data-sentry-element="Image" data-sentry-source-file="[slug].tsx" />
            </Link>
            <span className="text-sm opacity-80">
              {hardCodedTeamData.teamName || team.name}
            </span>
          </div>
          <div className="flex gap-2 text-center">
            <AnimatePresence mode="popLayout" data-sentry-element="AnimatePresence" data-sentry-source-file="[slug].tsx">
              {showCTA && isReady ? <motion.div initial={{
              y: -100,
              opacity: 0
            }} animate={{
              y: 0,
              opacity: 1
            }} exit={{
              y: -100,
              opacity: 0
            }}>
                  <TeamNavCTAButton team={team} />
                </motion.div> : null}
            </AnimatePresence>
            <div className={cn("text-sm -translate-y-[1px]", {
            "hidden lg:block": showCTA && isReady
          })}>
              <div className="flex space-x-1 items-center justify-center -translate-y-[1px]">
                {[...new Array(5)].map((_, i) => <StarIcon key={i} className="w-5 h-5 text-primary" />)}
              </div>
              <span className="opacity-80">4.9 Stars (40k+ reviews)</span>
            </div>
          </div>
        </div>
      </div>
    </>;
};
const TeamMarketingPage = ({
  team: initialTeam,
  slug,
  waitlist
}: {
  team: Team;
  slug: string;
  waitlist: boolean;
}) => {
  const {
    session: userSession
  } = useSession();
  const team = initialTeam || {} as Team;
  const hardCodedTeamData = teamData[slug] || teamData["forged"];
  const effectCalled = useRef(false);
  const isMounted = useIsMounted();
  const {
    observe: observeHero,
    inView: heroInView
  } = useInView({
    threshold: 0 // Default is 0
  });
  useEffect(() => {
    if (!slug) return;
    if (effectCalled.current) return;
    const abTestKey = `abtest_${slug}_team_page_v4`;
    const abTestGroup = Cookies.get(abTestKey);
    if (!abTestGroup) return;
    effectCalled.current = true;
    captureEvent({
      action: "Web Experiment Entered",
      name: abTestKey,
      category: "team_page",
      group: abTestGroup
    });
  }, [slug]);
  if (!teamData[slug]) {
    return <ErrorScreen />;
  }
  const suggestedEquipment = team.suggestedEquipmentDesc || hardCodedTeamData.overview.suggestedEquipment;
  const avatarImgixURL = team.avatarImgixURL || hardCodedTeamData.avatarImgixURL;
  return <ExperimentProvider data-sentry-element="ExperimentProvider" data-sentry-component="TeamMarketingPage" data-sentry-source-file="[slug].tsx">
      {userSession ? <WaitlistSwitch slug={slug} active={waitlist} /> : null}
      <>
        <DesktopQRModal launchProgramID={hardCodedTeamData.id || team?.id} data-sentry-element="DesktopQRModal" data-sentry-source-file="[slug].tsx" />
        <HeadContent title={hardCodedTeamData.og?.title || `Ladder | ${team.name} by ${team.coachNamesDesc}`} description={hardCodedTeamData.og.description || `${team.tagline}`} ogImageContent={hardCodedTeamData.og?.src || team.posterImgixURL} data-sentry-element="HeadContent" data-sentry-source-file="[slug].tsx" />
        <div>
          {hardCodedTeamData.launchDate && waitlist ? <div className="sticky top w-full px-4 py-2 text-center bg-background text-foreground text-sm lg:text-base">
              <span className="text-primary font-medium">
                🚀 Launching {hardCodedTeamData.launchDate}:
              </span>{" "}
              Claim your spot now!
            </div> : null}
          <Header team={{
          // @ts-ignore
          id: hardCodedTeamData.id,
          // @ts-ignore
          name: hardCodedTeamData.name,
          ...(team || {})
        }} slug={slug} showCTA={!heroInView && !waitlist} data-sentry-element="Header" data-sentry-source-file="[slug].tsx" />
          <div className="relative group translate-y-[-63px]">
            <div ref={observeHero} className={cn("pb-safe-or-10 group overflow-hidden relative flex center group h-[calc(100vh-36px)] lg:h-[calc(100vh-44px)] max-h-[1055px] md:min-h-[800px]")}>
              <Image className="absolute inset-0 w-full h-full pointer-events-none bg-cover object-cover object-top bg-center transition-all scale-100 block md:hidden" src={(hardCodedTeamData.hero.bgMobile || team.posterImgixURL || hardCodedTeamData.hero.bgDesktop) + "?w=1000"} alt="mobile hero background image" width={1000} height={763} loading="eager" data-sentry-element="Image" data-sentry-source-file="[slug].tsx" />
              <Image className="absolute inset-0 w-full h-full pointer-events-none bg-cover object-cover bg-center transition-all scale-100 hidden md:block" src={hardCodedTeamData.hero.bgDesktop + "?w=2800"} alt="desktop hero background image" width={2200} height={1048} loading="eager" data-sentry-element="Image" data-sentry-source-file="[slug].tsx" />
              <div style={{
              backgroundImage: `linear-gradient(75deg,rgba(0,0,0,.82),rgba(255,255,255,0) 81%)`
            }} className="absolute inset-0 pointer-events-none transition-all duration-300 mt-auto w-[70%] hidden lg:block" />
              <div className="absolute inset-0 pointer-events-none transition-all duration-300 mt-auto w-full h-[75%] bg-gradient-to-t from-background via-background/80 to-transparent lg:hidden" />
              <div className="max-w-md lg:container mx-auto mt-auto px-2 pb-4 lg:pb-0 lg:mt-0">
                <div className="flex relative justify-between mt-auto lg:max-w-xl lg:mr-auto lg:mt-0 text-left">
                  <div className={cn("text-foreground text-center lg:text-left")}>
                    <h3 className="text-2xl lg:text-4xl font-druk-wide text-foreground mb-2 md:mb-4 lg:mb-8">
                      {hardCodedTeamData.hero.title}
                    </h3>
                    <p className="mt-1 opacity-90 text-accent-foreground lg:text-lg group-hover:text-foreground mb-4 lg:mb-8">
                      {hardCodedTeamData.hero.description}
                    </p>
                    {waitlist ? <WaitlistEmailCapture teamName={team.name || hardCodedTeamData.teamName} redirectURL={hardCodedTeamData.waitlistRedirectURL} waitlistZapierWebhookURL={hardCodedTeamData.waitlistZapierWebhookURL} /> : <>
                        <div className="lg:hidden">
                          <TeamCTAButton team={team} />
                          <span className="">No Credit Card Required</span>
                        </div>
                        <div className="hidden lg:flex flex-col max-w-[60%] text-center">
                          <Image width={200} height={200} style={{
                        boxShadow: `0 0 2px #000, 0 0 20px #e6ff00`
                      }} className="rounded-lg w-[200px] mx-auto mb-4" src={hardCodedTeamData.hero.qrSrc} alt="QR Code" />
                          <span className="">☝️</span>
                          <span className="text-primary">
                            SCAN NOW TO TRY FOR FREE
                          </span>
                          <span className="">No Credit Card Required</span>
                        </div>
                      </>}
                  </div>
                </div>
              </div>
            </div>
            <div style={{
            colorScheme: "light"
          }} className="light">
              <div className="theme-ladder bg-background">
                <div style={{
                backgroundImage: `url(https://ladderteams.imgix.net/website/teams/dots.png)`
              }} className="bg-[#f2f2f2]">
                  <div className="text-foreground py-6 px-4 pb-36 lg:py-48 container mx-auto">
                    <h2 className="text-2xl lg:text-4xl font-druk-wide text-center mb-8 max-w-5xl mx-auto">
                      BUILD STRENGTH & GAIN CONFIDENCE, WITHOUT PLANNING
                      WORKOUTS.
                    </h2>
                    <div className="flex flex-col lg:flex-row items-center justify-center gap-4 text-center">
                      {[{
                      title: "NEW WORKOUTS EVERY WEEK",
                      description: "5+ new workouts designed and delivered to you every Sunday.",
                      iconURL: "https://ladderteams.imgix.net/website/teams/calendar.png"
                    }, {
                      title: "RESULTS IN LESS TIME",
                      description: "Make the most of your workouts and spend no time planning.",
                      iconURL: "https://ladderteams.imgix.net/website/teams/clock.png"
                    }, {
                      title: "ALWAYS WITH YOU",
                      description: "Stay consistent with challenging workouts that can be done from anywhere.",
                      iconURL: "https://ladderteams.imgix.net/website/teams/friend.png"
                    }, {
                      title: "REAL COACHING",
                      description: "Have confidence in your workouts and form, get answers to all your questions.",
                      iconURL: "https://ladderteams.imgix.net/website/teams/chat.png"
                    }].map(({
                      title,
                      description,
                      iconURL
                    }, index) => {
                      return <div key={index} className="max-w-[320px] flex flex-col">
                            <Image width={40} height={40} className="mx-auto block mb-4" src={iconURL} alt={title + " icon"} />
                            <h3 className="font-bold text-base mb-2">
                              {title}
                            </h3>
                            <p className="text-base">{description}</p>
                          </div>;
                    })}
                    </div>
                  </div>
                </div>
                <div className="text-foreground py-6 px-4 container max-w-4xl mx-auto -translate-y-[140px] -mb-[120px]">
                  <div className="mb-8 lg:mb-16">
                    <div className="flex gap-4 mx-auto justify-center items-center">
                      {avatarImgixURL ? <Image width={250} height={250} style={{
                      boxShadow: `0 0 10px rgba(0,0,0,.22)`
                    }} className={cn("rounded-full shadow-2xl mb-4 bg-white", {
                      "w-[150px] h-[150px] lg:w-[250px] lg:h-[250px]": !!hardCodedTeamData.overview.secondCoachImg,
                      "w-[250px] h-[250px]": !hardCodedTeamData.overview.secondCoachImg
                    })} src={avatarImgixURL + "?w=500"} alt="coach avatar" /> : null}
                      {hardCodedTeamData.overview.secondCoachImg ? <Image width={250} height={250} style={{
                      boxShadow: `0 0 10px rgba(0,0,0,.22)`
                    }} className={cn("rounded-full shadow-2xl mb-4 bg-white", {
                      "w-[150px] h-[150px] lg:w-[250px] lg:h-[250px]": !!hardCodedTeamData.overview.secondCoachImg,
                      "w-[250px] h-[250px]": !hardCodedTeamData.overview.secondCoachImg
                    })} src={hardCodedTeamData.overview.secondCoachImg + "?w=500"} alt="coach avatar" /> : null}
                    </div>
                    <h2 className="text-2xl lg:text-4xl font-druk-wide text-center mb-4 uppercase">
                      TRAIN WITH <br />{" "}
                      {team.coachNamesDesc || hardCodedTeamData.coachNamesDesc || hardCodedTeamData.coachName}
                    </h2>
                    <h3 className="text-lg lg:text-xl font-druk-wide text-center mb-4 opacity-60">
                      {hardCodedTeamData.overview.type}
                    </h3>
                    <p className="text-lg text-center mb-4 space-y-2 lg:space-y-4">
                      {hardCodedTeamData.overview.details.split("\n").map((line, index) => line && <span className="block" key={index}>
                              {line}
                              <br />
                            </span>)}
                    </p>
                  </div>
                  <div className="mb-8 lg:mb-16">
                    <h3 className="font-bold text-center mb-4 lg:text-lg">
                      WORKOUT OVERVIEW
                    </h3>
                    <div className="flex flex-wrap items-center justify-center gap-4 lg:gap-8">
                      <div className="text-center">
                        <div className="flex justify-center items-center gap-2">
                          {new Array(hardCodedTeamData.overview.flames).fill(null).map((_, index) => {
                          return <Image key={index} width={32} height={32} className="w-6 lg:w-8" src="https://ladderteams.imgix.net/website/teams/flame.svg" alt="flame" />;
                        })}
                          {new Array(3 - hardCodedTeamData.overview.flames).fill(null).map((_, index) => {
                          return <Image key={index} width={32} height={32} className="w-6 lg:w-8 opacity-50" src="https://ladderteams.imgix.net/website/teams/flame.svg" alt="flame" />;
                        })}
                        </div>
                        {hardCodedTeamData.overview.intensity}
                      </div>
                      <div className="flex flex-col text-center items-center">
                        <Image className="w-8 lg:w-10" width={40} height={40} src="https://ladderteams.imgix.net/website/teams/clock.svg" alt="clock" data-sentry-element="Image" data-sentry-source-file="[slug].tsx" />
                        {hardCodedTeamData.overview.avgWorkoutDurDesc}
                      </div>
                    </div>
                  </div>
                  {/* <div className="mb-8 lg:mb-16">
                    <h3 className="font-bold text-center mb-4 lg:text-lg">
                      SIMILAR TO:
                    </h3>
                    <div className="flex flex-wrap items-center justify-center gap-4">
                      <Badge className="text-base">
                        {hardCodedTeamData.overview.similarTo}
                      </Badge>
                    </div>
                   </div> */}
                  {suggestedEquipment?.length ? <div>
                      <h3 className="font-bold text-center mb-4 lg:text-lg">
                        SUGGESTED EQUIPMENT
                      </h3>
                      <div className="flex flex-wrap items-center justify-center gap-4">
                        {suggestedEquipment.split(", ").map((equipment, index) => <Badge key={index} className="text-base">
                              {equipment}
                            </Badge>)}
                      </div>
                    </div> : null}
                </div>
                <div className="bg-[#f8f7f3] text-foreground py-6 lg:py-12 px-4">
                  <div className="mb-8 lg:mb-16">
                    <h3 className="font-bold text-center mb-4">
                      EXCLUSIVELY ON LADDER
                    </h3>
                    <h2 className="text-xl lg:text-4xl font-druk-wide text-center mb-4 uppercase">
                      EXPERIENCE AN APP <br /> BUILT FOR STRENGTH
                    </h2>
                    <p className="text-lg text-center mb-4">
                      In-ear coaching, video demonstrations and precise pacing
                      built-in.
                    </p>
                  </div>
                  <div className="flex flex-col lg:flex-row items-center justify-center gap-4 text-center">
                    {[{
                    title: "PERSONAL TRAINING \n ON YOUR TERMS"
                  }, {
                    title: "REAL COACHING \n BY AN EXPERT"
                  }, {
                    title: "A PLAN YOU \n CAN COUNT ON"
                  }].map(({
                    title
                  }, index) => {
                    return <div key={index} className="max-w-[320px]">
                          <h3 className="text-lg font-druk-wide mb-2">
                            {title}
                          </h3>
                          <Image width={320} height={628} className="mx-auto block w-full" style={hardCodedTeamData.appSSStyle?.[index] || {}} src={hardCodedTeamData.appSS[index]} alt={title} />
                        </div>;
                  })}
                  </div>
                </div>
              </div>
            </div>
            <div className={cn("h-[80vh] max-h-[600px] lg:max-h-[800px] w-full bg-cover bg-center transition-all scale-100 flex items-center justify-center relative")}>
              <Image className="absolute inset-0 w-full h-full object-cover block lg:hidden" src={(hardCodedTeamData.quote.bgMobile || hardCodedTeamData.quote.bgDesktop) + "?w=1600"} alt="background image" width={1600} height={1000} data-sentry-element="Image" data-sentry-source-file="[slug].tsx" />
              <Image className="absolute inset-0 w-full h-full object-cover hidden lg:block" src={hardCodedTeamData.quote.bgDesktop + "?w=2600"} alt="background image" width={2600} height={1048} data-sentry-element="Image" data-sentry-source-file="[slug].tsx" />
              {hardCodedTeamData.quote.showCopy ? <>
                  <div className="absolute inset-0 w-full pointer-events-none transition-all duration-300 bg-gradient-to-t from-background/90 to-black/10 mt-auto" />
                  <div className="container mx-auto max-w-lg flex flex-col items-center text-center relative gap-4">
                    <Image width={40} height={30} className="w-10" src="https://ladderteams.imgix.net/website/teams/quote-open.svg" alt="quote open" />
                    <h2 className="text-2xl lg:text-4xl font-druk-wide text-foreground">
                      {hardCodedTeamData.quote.copy || team.mantra}
                    </h2>
                    <Image width={40} height={30} className="w-10 transform rotate-180" src="https://ladderteams.imgix.net/website/teams/quote-open.svg" alt="quote closed" />
                  </div>
                </> : null}
            </div>
          </div>
        </div>
        <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="[slug].tsx">
          {waitlist && !heroInView && isMounted ? <motion.div initial={{
          y: 100,
          opacity: 0
        }} animate={{
          y: 0,
          opacity: 1
        }} exit={{
          y: 100,
          opacity: 0
        }} className="fixed bottom-0 z-10 flex flex-col items-center justify-center bg-black/80 backdrop-blur w-full lg:w-[32rem] mx-auto inset-x-0 rounded-t-lg px-4 pb-4 pt-2">
              <p className="text-center text-sm pb-2 lg:text-base">
                No Credit Card Required • 100% Risk-Free
              </p>
              <WaitlistEmailCapture teamName={team.name || hardCodedTeamData.teamName} showDescription={false} redirectURL={hardCodedTeamData.waitlistRedirectURL} waitlistZapierWebhookURL={hardCodedTeamData.waitlistZapierWebhookURL} />
            </motion.div> : null}
        </AnimatePresence>
        <Footer data-sentry-element="Footer" data-sentry-source-file="[slug].tsx" />
      </>
    </ExperimentProvider>;
};
const WaitlistSwitch = ({
  active,
  slug
}) => {
  const {
    session
  } = useSession();
  const [waitlistActive, setWaitlistActive] = useState(active);
  const [loading, setLoading] = useState(false);
  const {
    isAdmin
  } = useCurrentUser();
  const handleToggle = async () => {
    try {
      setWaitlistActive(!waitlistActive);
      setLoading(true);
      await fetch(`/api/team/waitlist-toggle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session}`
        },
        body: JSON.stringify({
          slug
        })
      });
      // refresh page
      window.location.reload();
    } catch (error) {
      setWaitlistActive(!waitlistActive);
      setLoading(false);
      console.error("Error toggling waitlist", error);
      Sentry.captureException(error);
      toast.error("Something went wrong");
    }
  };
  if (!isAdmin) return null;
  return <div className={cn("absolute flex gap-2 items-center text-foreground lg:top-[calc(100vh-6rem)] right-4 z-50 px-4 py-2 rounded-lg bg-secondary", {
    "top-24": active,
    "top-16": !active
  })} data-sentry-component="WaitlistSwitch" data-sentry-source-file="[slug].tsx">
      <span>waitlist</span>
      <Switch disabled={loading} checked={waitlistActive} onCheckedChange={handleToggle} className="" data-sentry-element="Switch" data-sentry-source-file="[slug].tsx" />
      {loading ? <Spinner className="w-4 h-4" /> : null}
    </div>;
};
export const getStaticPaths: GetStaticPaths = async () => {
  try {
    const teams = await listTeams();
    const paths = teams.map(team => team?.id && team?.name && {
      params: {
        slug: slugify(team.name, {
          lower: true
        })
      }
    }).filter(Boolean);
    const hasTeamTransform = teams.some(team => slugify(team.name, {
      lower: true
    }) === "transform");
    const hasTeamAscend = teams.some(team => slugify(team.name, {
      lower: true
    }) === "ascend");
    const hasTeamVitality = teams.some(team => slugify(team.name, {
      lower: true
    }) === "vitality");
    if (!hasTeamTransform) {
      paths.push({
        params: {
          slug: "transform"
        }
      });
    }
    if (!hasTeamAscend) {
      paths.push({
        params: {
          slug: "ascend"
        }
      });
    }
    if (!hasTeamVitality) {
      paths.push({
        params: {
          slug: "vitality"
        }
      });
    }
    return {
      paths,
      fallback: true // false or "blocking"
    };
  } catch (error) {
    console.error("Error fetching paths", error);
    return {
      paths: [],
      fallback: true
    };
  }
};
export const getStaticProps = async ({
  params
}: {
  params: {
    slug: string;
  };
}) => {
  try {
    const waitlist = await kv.get(`${params.slug}_page_waitlist_${isDev ? "dev" : "prod"}`);
    if (params.slug === "vitality") {
      return {
        props: {
          team: {
            name: "Vitality",
            id: "c6fc4fab-75fd-40ba-a893-b5a6539a569c"
          },
          slug: "vitality",
          waitlist: !!waitlist
        }
      };
    }
    if (params.slug === "transform") {
      return {
        props: {
          team: {
            name: "Transform",
            id: "8362e136-3d69-441f-bf5f-58975379cc6f"
          },
          slug: "transform",
          waitlist: !!waitlist
        }
      };
    }
    if (params.slug === "ascend") {
      return {
        props: {
          team: {
            name: "Ascend",
            id: "700e6ad1-64fd-4255-9ae1-7b1300b6ecd9"
          },
          slug: "ascend",
          waitlist: !!waitlist
        }
      };
    }
    const team = await getTeamFromSlug(params?.slug as string);
    return {
      props: {
        team,
        slug: params?.slug,
        waitlist: !!waitlist
      }
    };
  } catch (error) {
    console.error("Error fetching props", error);
    return {
      props: {
        team: {},
        slug: params?.slug,
        waitlist: false
      }
    };
  }
};
TeamMarketingPage.Layout = OptimizeLayout;
export default TeamMarketingPage;